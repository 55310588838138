import React from "react";
import HomePageContent from "../Contetent/HomePageContent";
import Enquire from "../Contetent/Enquire";
import History from "../Contetent/History";
import About from "../Contetent/About";
import Mennu from "../Contetent/Mennu";
// import Silde from "../Contetent/Silde";
import Videos from "../Contetent/Videos";
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Nellai Karupatti Coffee | Thati Bellam Coffee | Karupatti Coffee
          Franchise
        </title>
        <meta
          name="description"
          content="Experience the rich aroma & unique taste of Nellai Karupatti Coffee (Thati Bellam Coffee), made from palm jaggery & coffee beans. Start your own Nellai Karupatti Coffee Franchise today!"
        />
        <meta
          name="keywords"
          content="Karupatti Coffee, Thati Bellam Coffee, Nellai Karupatti Coffee, Karupatti Coffee Franchise, Nellai Karupatti coffee franchise, Thati Bellam Coffee franchise"
        />

        <link rel="canonical" href="https://nellaikarupatticoffee.com/" />
        <meta property="og:site_name" content="Nellai Karupatti Coffee" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Nellai Karupatti Coffee | Thati Bellam Coffee | Karupatti Coffee Franchise"
        />
        <meta
          property="og:description"
          content="Experience the rich aroma & unique taste of Nellai Karupatti Coffee (Thati Bellam Coffee), made from palm jaggery & coffee beans. Start your own Nellai Karupatti Coffee Franchise today"
        />
        <meta property="og:url" content="https://nellaikarupatticoffee.com" />

        <meta
          property="og:image"
          content="https://nellaikarupatticoffee.com/assets/images/Navbar/Nkc%20Logo.png"
        />
      </Helmet>
    
      <div>
        <HomePageContent />
        <Enquire />
        <History />
        <About />
        <Mennu />
        {/* <Silde />  */}
        <Videos />
      </div>
    </>
  );
};

export default Home;
