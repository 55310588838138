import React from "react";
import { Link } from "react-router-dom";

const Button = ({ btn, link,btn2, children, btn1, onClick, target }) => {
  return (
    <>
      <Link to={link} target={target} onClick={btn1 ? onClick : undefined} className="">
        <button
          className={`relative z-10 cursor-pointer overflow-hidden border-none md:pt-3.5 font-avigea text-2xl transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:rounded-lg  before:transition-transform before:duration-300 before:content-['']  before:hover:scale-x-100 ${
            btn &&
            "  rounded-full bg-[#702F2F] xs:text-lg px-3.5 py-2  text-[#FFC20E]  before:bg-[#FDD78D] hover:text-amber-950"
          } 
          ${
            btn1 &&
            "rounded-lg bg-[#702F2F] py-2 text-[#FFC20E]   before:bg-[#FDD78D] hover:text-amber-950 xs:w-[230px]  md:h-[60px] md:w-[481px] "
          } 
          ${
            btn2 &&
            "rounded-lg bg-[#702F2F] py-4 xs:py-4  xs:px-5 text-[#FFC20E]  before:bg-[#FDD78D] hover:text-amber-950 xs:w-[auto]  md:h-[60px] md:w-[481px] "
          } 
          `}
        >
          {children}
        </button>
      </Link>
    </>
  );
};

export default Button;
