import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [supportToggle, setSupportToggle] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setOpen(false);

        setSupportToggle(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  console.log(open, supportToggle);

  const Nav = [
    {
      id: 1,
      title: "Home",
      path: "/",
    },
    {
      id: 2,
      title: "Franchise",
      path: "/franchise",
    },
    {
      id: 3,
      title: "Products",
      path: "/products",
    },
    {
      id: 4,
      title: "About",
      path: "/about",
    },
    {
      id: 5,
      title: "Contact Us",
    },
  ];

  return (
    <nav
      className="z-50 flex h-[100px] w-full items-end border-b-2 border-[#702F2F]"
      style={{
        backgroundImage: "url('../assets/images/home/home.png')",
      }}
    >
      <div
        className="z-50 m-auto flex h-100% items-end gap-12 xs:w-[100%] md:w-[80%] md:justify-between  "
        ref={navRef}
      >
        <div className="nav flex h-100% w-full items-center md:justify-between">
          <div class="flex gap-4 xs:w-[50%] md:w-[50%]">
            <div className="flex items-center">
              <Link to="/">
                <div className="flex items-center gap-3">
                  <div className="w-[170px] ">
                    <img
                      src="../assets/images/Navbar/Nkc Logo.png"
                      alt="logo"
                    ></img>
                  </div>

                  <p className="font-avigea  text-[#481212] xs:m-auto xs:h-[100%] xs:w-[100%] xs:text-[18px] s:mt-10 xs:mt-8 s:text-2xl sm:mt-10  md:text-[25px]">
                    Nellai <br /> Karupatti Coffee
                  </p>
                </div>
              </Link>
              <div className={supportToggle ? "support show" : "support"}>
                <div
                  onClick={() => setOpen(!open)}
                  className="absolute right-8 top-10 cursor-pointer text-2xl   xs:h-auto"
                >
                  <FontAwesomeIcon
                    icon={open ? faXmark : faBars}
                    className=" z-50 ml-3 transition duration-300 group-hover:rotate-180 md:hidden "
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class={`absolute block  w-full md:relative md:right-0 
            md:flex 
            ${!open && "hidden"}
            ${
              open && " left-0 top-32"
            } transition-left  justify-between bg-white  duration-300 ease-in-out lg:relative lg:top-5 lg:flex lg:bg-transparent`}
          >
            {Nav?.map((data) => {
              return (
                <div>
                  <ul className="">
                    <li
                      className="flex h-[55px]   items-center border-[#702F2F] px-5 pt-2 text-center text-[#fff] hover:border-b-4 "
                      onClick={() => setOpen(false)}
                    >
                      <Link
                        to={data?.path}
                        className="cursor-pointer font-avigea text-xl font-normal  text-[#702F2F]  "
                      >
                        {data?.title === "Contact Us" ? (
                          <div
                            className="flex h-[50px] w-[180px] items-center pb-4  pt-0"
                            style={{ zIndex: -1 }}
                          >
                            <Button link={"/contactus"} btn>
                              Contact Us
                            </Button>
                          </div>
                        ) : data?.title === "About" ? (
                          "About Us"
                        ) : (
                          data.title
                        )}
                      </Link>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
