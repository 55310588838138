import React from 'react'
import ImageCard from '../../components/ImageCard'
const Milet = () => {
  const milet = [
    {
      id: 1,
    img: "../assets/images/product/milet1.png",
    },
    {
      id: 2,
    img: "../assets/images/product/milet2.png",
},{
      id: 3,
    img: "../assets/images/product/milet3.png",
},
  ]
  return (
    <div
      className=" xs:h-[auto] s:h-[auto] md:h-auto md:w-full md:py-10 "
      style={{
        backgroundImage: "url('../assets/images/product/Frame5.png')",
      }}
    >
      <div className=" m-auto md:w-[70%]">
        <div className="py-20  xs:px-3 xs:py-6">
          {/* <div
            className="xs:rounded-bl-0 md:rounded-bl-0 m-auto bg-[#FFC20E] text-center font-avigea text-3xl  xs:flex xs:h-[65px]  xs:w-[230px]
          xs:items-center xs:justify-center xs:rounded-br-[100px] xs:rounded-tl-[150px] xs:rounded-tr-[100px] xs:bg-[#FFC20E] xs:text-center xs:font-avigea xs:text-3xl xs:text-[#481212] sm:w-[280px]  md:flex md:h-[98px]  md:w-[513px]
          md:items-center md:justify-center md:rounded-br-[250px] md:rounded-tl-[500px]
         md:rounded-tr-[250px] md:text-[#481212] xs:pt-2"
          >
            <h1 class="xs:text-3xl md:text-5xl">Milet Food</h1>
          </div> */}
          <img
            src="../assets/images/product/milet.png"
            alt="line"
            // class="xs:-mt-1.5 s:-mt-2 sm:-mt-3  md:-mt-5 lg:-mt-5 "
          />
        </div>
      </div>
      <div className=" m-auto w-[100%]">
        <ImageCard data={milet} />
      </div>
    </div>
  );
}

export default Milet