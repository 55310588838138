import React from "react";

const Models = () => {
  return (
    <div
      className="md:h-[1300px] md:w-full "
      style={{
        background:
          "url(../assets/images/Franchise/bg.png) no-repeat center/cover",
      }}
    >
      <div className="m-auto xs:pb-10 max-w-[1500px]">
        <div className=" h-[100%] w-[100%] ">
          <p className="m-auto h-auto  w-[200px]  font-avigea text-2xl text-[#481212] xs:py-4 xs:px-12  md:relative md:left-96 md:top-40">
            Investement <br />
            starts from <br />
            <span className="text-7xl">5.5</span>
            <br />
            lakhs
          </p>
        </div>

        <div className=" m-auto xs:w-[270px] md:w-[470px] ">
          <div className=" h-[137px] w-[100%] items-center  rounded-br-3xl rounded-tl-3xl rounded-tr-3xl bg-[#481212]">
            <p className="m-auto flex h-[88px] w-[200px] pt-6 text-center font-avigea text-5xl text-[#FFC20E]">
              Franchise Models
            </p>
          </div>
        </div>
        <div className="m-auto  w-[70%] items-center xs:hidden md:flex ">
          <div>
            <img src="../assets/images/Franchise/rightarrow.png" alt="line" />
          </div>
          <div
            style={{
              margin: "auto",
            }}
          >
            <img src="../assets/images/Franchise/down.png" alt="line" />
          </div>

          <div>
            <img src="../assets/images/Franchise/leftarrow.png" alt="line" />
          </div>
        </div>
        <div className=" m-auto pt-20 xs:hidden md:flex">
          {contactDetails?.map((data) => {
            return (
              <div
                key={data.id}
                className="mx-12 xs:w-[50%] xs:m-auto md:m-auto   md:w-[100%] xs:mb-20 "
                style={{
                  background:
                    "url('../assets/images/Franchise/main1.png')no-repeat center/contain",
                }}
              >
                <div >
                  <p className="pt-10 text-center font-avigea md:text-5xl xs:text-3xl text-[#481212] md:h-[25vh] xs:pt-10 ">
                    {data?.title}
                  </p>
                  <div className=" lg:flex md:flex md:flex-col  md:items-center  md:px-8 ">
                    <div
                      className="mb-6  flex h-[20vh]  items-center  justify-center text-center md:text-3xl md:w-[70%] m-auto  font-baumans text-[#FFC20E]   "
                     
                    >
                      <p>{data?.address1}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="m-auto pt-20 xs:contents md:hidden ">
          {contactDetails?.map((data) => {
            return (
              <>
                <img src={data?.img} className="m-auto h-[150px]" />
                <div
                  key={data.id}
                  className="  md:m-auto "
                  style={{
                    background:
                      "url('../assets/images/Franchise/main1.png') no-repeat center/contain",
                    width: "90%",
                    margin:"auto",
                    height:"100%"
                  }}
                >
                  <div>
                    <p className=" text-center font-avigea md:text-5xl xs:text-lg text-[#481212] xs:pt-10">
                      {data?.title}
                    </p>
                    <div className="flex flex-col items-center   ">
                      <div
                        className="mb-6 items-center justify-center text-center font-baumans text-[#FFC20E] md:flex xs:h-[30vh] xs:flex xs:items-center xs:text-xl xs:mt-9 xs:px-6 "
                    
                      >
                        <p>{data?.address1}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const contactDetails = [
  {
    id: 1,
    title: "Kiosk",
    address1: "Square feet Needed 50 - 100 ft",
    img: "../assets/images/Franchise/down.png",
  },
  {
    id: 2,
    title: "shop",
    address1: "Square feet Needed 250 - 800 ft",
    img: "../assets/images/Franchise/down.png",
  },

  {
    id: 3,
    title: "Vacant Land",
    address1: "Square feet Needed 1000 - 2000 ft",
    img: "../assets/images/Franchise/down.png",
  },
];

export default Models;
