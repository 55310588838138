import React from "react";
import Enquiry from "./Enquiry";
import Coffee from "./Coffee";
import Steps from "./Steps";
import Models from "./Models";
import { Helmet } from "react-helmet";

const Franchise = () => {
  return (
    <>
      <Helmet>
        <title>Karupatti Coffee Franchise - Low Investment High Profit </title>
        <meta
          name="description"
          content="Start your own Nellai Karupatti Coffee Franchise today just @ Rs.5.5 lakhs. Area required: 50 to 100 ft, No royalty, Lifetime validity. Contact: +91 63741 14632"
        />
        <meta
          name="keywords"
          content="Karupatti Coffee, Nellai Karupatti Coffee, Karupatti Coffee Franchise, Nellai Karupatti coffee franchise"
        />
        <link
          rel="canonical"
          href="https://nellaikarupatticoffee.com/franchise/"
        />
        <meta property="og:site_name" content="Nellai Karupatti Coffee" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Start your own Nellai Karupatti Coffee Franchise In your city today! Low investment, high returns, and 100+ franchises across India. Book Your Shop Now!"
        />
        <meta
          property="og:url"
          content="https://nellaikarupatticoffee.com/franchise"
        />
        <meta
          property="og:image"
          content="https://nellaikarupatticoffee.com/assets/images/Navbar/Nkc%20Logo.png"
        />
      </Helmet>
      <Enquiry />
      <Coffee
        text="Good reasons to start a franchise with us"
        content={title}
      />
      <Models />
      <Steps text="Steps to open a karupatti coffee franchise" content={items} />
    </>
  );
};

const title = [
  {
    id: 1,
    img: "../assets/images/Franchise/bullet.png",

    title: "Excellent opportunity for people with interests in business.",
  },
  {
    id: 2,
    img: "../assets/images/Franchise/bullet.png",

    title: "Easy to handle, no chef experience is needed.",
  },
  {
    id: 3,
    img: "../assets/images/Franchise/bullet.png",

    title: "Return of investment (ROI) within 8 to 12 months.",
  },
  {
    id: 4,
    img: "../assets/images/Franchise/bullet.png",

    title: "More stable income with less investment.",
  },
  {
    id: 5,
    img: "../assets/images/Franchise/bullet.png",

    title: "Automated billing system.",
  },
  {
    id: 6,
    img: "../assets/images/Franchise/bullet.png",

    title: "Precautionary steps and ideas to avoid loss.",
  },
  {
    id: 7,
    img: "../assets/images/Franchise/bullet.png",

    title: "No royalty.",
  },
  {
    id: 8,
    img: "../assets/images/Franchise/bullet.png",

    title: "Life time validity.",
  },
  {
    id: 9,
    img: "../assets/images/Franchise/bullet.png",
    title: "Free training",
  },
  {
    id: 10,
    img: "../assets/images/Franchise/bullet.png",

    title: "Continued customer support",
  },
];
  const items = [
  {
    id: 1,
    img: "../assets/images/Franchise/bullet.png",

    title: "Advance payment is required to book a franchise with us..",
  },
  {
    id: 2,
    img: "../assets/images/Franchise/bullet.png",

    title: "After visiting the booked location, we will give you our consent to start the franchise..",
  },
  {
    id: 3,
    img: "../assets/images/Franchise/bullet.png",

    title: "After the franchise amount is paid in full, franchise agreement is signed by both parties. .",
  },
  {
    id: 4,
    img: "../assets/images/Franchise/bullet.png",

    title: "All required equipment will be sent within 3 weeks to the location..",
  },
  {
    id: 5,
    img: "../assets/images/Franchise/bullet.png",

    title: "Training will be given to the workers and also the franchise owner.Inventory management procurement details will also be provided..",
  },
  
  ];

export default Franchise;
