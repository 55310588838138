import React, { useState } from "react";
import emailjs from "emailjs-com";
import Button from "../../components/button/Button";

const Form = () => {
  const initialValues = {
    name: "",
    mobilenumber: "",
    Email: "",
    city: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = (e) => {
    
    e.preventDefault();
    const errors = validate(formValues);

    setFormErrors(errors);
    setIsSubmit(true);
    if (Object.keys(errors).length === 0) {
      emailjs
        .send(
          "service_98j0g5t",
          "template_uzcjv1n",
          formValues,
          "lA9r1tX5ScJMuIiI3",
        )
        .then((response) => {
                    setFormValues({
                      name: "",
                      mobilenumber: "",
                      Email: "",
                      city: "",
                    });

          alert("The Form submitted successfully.");

          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          alert("Error sending email: " + error);
          console.error("Error sending email:", error);
        });
    } else {
      alert("Kindly enter the required fields");
      console.log("Form has errors. Submit prevented.");
    }
  };
  const validate = (values) => {
    const errors = {};
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!values.name) {
      errors.name = "Name is required!";
    }

    if (!values.Email) {
      errors.Email = "Email is required!";
    } else if (!regex.test(values.Email)) {
      errors.Email = "This is not a valid email format!";
    }

    if (!values.mobilenumber) {
      errors.mobilenumber = "Mobile number is required!";
    } else if (!/^\d{10}$/.test(values.mobilenumber)) {
      errors.mobilenumber = "Mobile number should contain exactly 10 numbers!";
    }

    if (!values.city) {
      errors.city = "Location is required!";
    }

    return errors;
  };

  return (
    <div style={{width:500,height:500}}>
      <h1 className="text-center font-avigea text-[#481212] xs:pb-12 xs:text-2xl md:text-4xl">
        Franchise Enquiry
      </h1>
      <iframe
        height="361px"
        width="100%"
        title="Franchise Enquiry Form"
        src='https://creatorapp.zohopublic.in/info_nkcfoods/franchise-enquiry/form-embed/Enquiry/gN8VBMrqzjZWhdFjK1mvbAWpQzmHztZqMJCN7r6gSmx5VvubBGmYODpWnYRb6PrtqPXRjhmAezf7y61zWKPKXq0zwsA6CbQsrrPf'
      ></iframe>
    </div>
  );
};

export default Form;
