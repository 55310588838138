import React from "react";
import Cofe from "../../components/Cofe";
const Coffee = (props) => {
  return (
    <div
      className="m-auto md:h-[auto] w-[100%] items-center align-middle "
      style={{
        background:
          "url(../assets/images/Franchise/Vector.png) no-repeat center/cover",
      }}
    >
      <div className="m-auto h-auto max-w-[1500px] items-center ">
        <img src="../assets/images/Franchise/main.png" alt="line" />
        <div
          className="rounded-bl-0 m-auto flex items-center justify-center rounded-br-[150px] rounded-tl-[250px] rounded-tr-[150px] bg-[#FFC20E] text-center font-avigea text-[#481212] xs:-mt-12 xs:h-[50px]  xs:w-[220px] s:w[300px] sm:w-[430px]  xs:md:-mt-24 md:-mt-24
           md:h-[98px] lg:h-[94px] md:w-[800px] lg:w-[830px]  md:text-4xl 
         "
        >
          {props?.text}
        </div>

        <div className="px-5 md:py-24 xs:py-7 xs:flex xs:flex-col  s:items-center md:w-[100%] md:flex-row md:gap-4">
          <div className="md:w-[40%] ">
            <img src="../assets/images/Franchise/first.png" alt="line"  class="lg:mt-4"/>
          </div>
          <div className="font-avigea text-[#FFC20E]  xs:text-xl md:w-[60%]  md:text-4xl">
            <Cofe data={props?.content} flexDirection={props?.flexDirection} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coffee;
