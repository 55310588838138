import React from "react";

const History = () => {
  return (
    <div className="w-[100%] bg-[#FFC20E] s:h-[auto] md:h-[950px]">
      <div className="m-auto items-center  md:flex  md:gap-12 ">
        <div className=" items-center xs:flex xs:flex-col  xs:px-3 xs:py-7  md:mt-10   md:flex md:flex-col md:px-28">
          <h1
            className="rounded-bl-0 s:[400px] m-auto mb-10 rounded-br-[150px] rounded-tl-[180px] rounded-tr-[150px] bg-[#481212] pt-6
         text-center font-avigea text-[#FFC20E] xs:mb-5 xs:w-[274px] xs:pt-2 xs:text-xl md:h-[140px] md:w-[480px] md:pt-6 md:text-5xl "
          >
            History of Nellai <br />
            Karupatti coffee
          </h1>
          <p className="text-justify font-baumans  text-[#300808] xs:m-[auto] xs:w-[90%] xs:text-xl s:w-[auto] md:w-[453px]  md:text-3xl">
            India is a country known for its Heritage and culture. In the
            initial days of our lives, our grandma brewed us coffee with freshly
            made palm jaggery. The ‘Original’ karupatti coffee. Every sip had a
            unique flavour and was full of richness. Great memories, Great
            taste. In today’s world, full of trends and growing popularity of
            western food culture, we want to remain classic and decided to
            revive the sweet memories. This led to the idea of
            <span className=" ml-1 font-avigea text-[18px]">
              NELLAI KARUPATTI COFFEE.
            </span>
          </p>
        </div>
        <div className="xs:mx-6 xs:py-4  md:mt-10 md:pt-20 lg:flex lg:flex-col ">
          <img
            src="../assets/images/home/history.png"
            alt="history"
            height="100%"
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
};

export default History;
