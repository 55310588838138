import React from "react";
import Franchisee from "../Contactus/Franchisee";
import Slide from "../Contetent/Silde";
import { Helmet } from "react-helmet";
const More = () => {
 
  return (
    <>
      <Helmet>
        <title>About Us-Nellai Karupatti Coffee </title>
        <meta
          name="description"
          content="Experience the essence of tradition at Nellai Karupatti Coffee. Savor our signature Karupatti coffee and snacks, serving healthy, satisfying delights"
        />
        <meta name="keywords" content="About Nellai Karupatti Coffee" />
        <link rel="canonical" href="https://nellaikarupatticoffee.com/about/" />
        <meta property="og:site_name" content="Nellai Karupatti Coffee" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="About Us - Nellai Karupatti Coffee"
        />
        <meta
          property="og:description"
          content="Experience the essence of tradition at Nellai Karupatti Coffee. Savor our signature Karupatti coffee and snacks, serving healthy, satisfying delights"
        />
        <meta
          property="og:url"
          content="https://nellaikarupatticoffee.com/about"
        />
        <meta
          property="og:image"
          content="https://nellaikarupatticoffee.com/assets/images/Navbar/Nkc%20Logo.png"
        />
      </Helmet>

      <div className="h-auto bg-[#481212]  md:py-2 2xl:w-[100%]">
        <div className=" m- m-auto  h-auto sm:px-10 lg:h-auto   2xl:w-[1500px]">
          <div className="m-auto xs:h-auto lg:h-[500px] ">
            <div className="xs:px-4 xs:py-5  md:py-5">
              <div
                className="rounded-bl-0 m-auto items-center justify-center rounded-br-[100px] rounded-tl-[150px] rounded-tr-[100px]  bg-[#FFC20E] text-center  font-avigea
          text-[#702F2F] xs:h-[60px] xs:w-[180px] xs:text-xl sm:w-[280px]
         sm:text-lg md:flex md:h-[100px] md:w-[533px] md:text-4xl "
              >
                <h1>About Nellai Karupatti Coffee</h1>
              </div>
              <img
                src="../assets/images/home/homeline.png"
                alt="line"
                class="xs:-mt-1.5 s:-mt-2 md:-mt-6 lg:-mt-5"
              />
            </div>
            <div className=" flex-col gap-10 text-[#FFC20E] xs:block  xs:px-3 xs:text-xl sm:block md:flex md:text-2xl">
              <p className=" pb-3 text-justify font-baumans">
                <span className=" mr-2 font-avigea text-[18px]">
                  NELLAI KARUPATTI COFFEE
                </span>
                is an authentic coffee outlet. We are known for our signature
                Karupatti coffee and other Karupatti infused snacks, which are
                both traditional and tasty.
              </p>
              <p className=" text-justify font-avigea text-[#E8A21A] xs:text-xl md:text-2xl">
                To ‘Serve Healthy’ is our priority and ‘Your Satisfaction’ is
                our aim.
              </p>
              <p className=" text-justify font-baumans xs:text-xl md:text-2xl">
                Our vision is to serve healthy organic food and reintroduce the
                traditional food system. With the dawn of the modern era, we are
                committed to bring back authentic foods infused with innovation.
              </p>
            </div>
          </div>

          <Franchisee />
        </div>
        <Slide />
      </div>
    </>

   
  );
};

export default More;

