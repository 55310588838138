import React from 'react'

const Videos = () => {
  return (
    <div className="image-container  h-auto  items-center justify-center  bg-[#481212] py-16 ">
      <div className="m-auto flex h-auto  items-center justify-center gap-8 px-7 xs:flex xs:flex-col md:flex  md:flex-row">
        <iframe
          className="w-full max-w-[450px]"
          height="316"
          src="https://www.youtube.com/embed/Sc3xH95I1tQ"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="w-full max-w-[450px]"
          height="316"
          src="https://www.youtube.com/embed/ac9WwE1pc30"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="w-full max-w-[450px]"
          height="316"
          src="https://www.youtube.com/embed/kCygn4DaaVA?feature=share"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default Videos