import React from "react";
import Button from "../../components/button/Button";

const Enquire = () => {
  return (
    <div
      className="flex h-auto w-[100%] flex-col  text-center xs:gap-10 xs:pb-12  md:gap-20 md:pt-28"
      style={{
        background:
          "url(../assets/images/home/brown.png) no-repeat center/cover",
        marginTop: "-1px",
      }}
    >
      <p className=" font-avigea  text-[#FFC20E]  xs:text-3xl md:text-6xl">
        Be your 'OWN BOSS' at an 'AFFORTABLE INVESTMENT '
      </p>

      <div className="flex justify-around xs:px-5  md:w-[100%]">
        <img src="../assets/images/home/line.png" alt="line" />
      </div>

      <h5 className=" font-avigea  text-[#FFC20E]  xs:text-3xl md:text-6xl">
        Invest in ' YOUR FUTURE ' with ' OUR FRANCHISE '
      </h5>
      <div
        className=" text-center font-avigea  text-5xl xs:px-4 md:p-8 md:px-7 md:py-7"
        style={{ fontSize: "50px" }}
      >
        <Button link={"/Franchise"} target={"_parent"} btn2>
          Enquiry Now
        </Button>
      </div>
    </div>
  );
};

export default Enquire;
