import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import Products from "./pages/products/Products";
import Franchise from "./pages/Franchise/Franchise";
import Location from "./pages/Contactus/Location";
import Franchisee from "./pages/Contactus/Franchisee";
import More from "./pages/more/More";
import Contactus from "./pages/Contactus/Contactus";
import ReactGA from "react-ga";
import TermsCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";

ReactGA.initialize("G-0P6PD1FZRX");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const WEB_SCHEMA = JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Nellai Karupatti Coffee",
    url: "https://nellaikarupatticoffee.com/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://nellaikarupatticoffee.com/{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  });

  const ORG_SCHEMA = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Nellai Karupatti Coffee",
    url: "https://nellaikarupatticoffee.com/",
    logo: "https://nellaikarupatticoffee.com/assets/images/footer/Nkc%20Logo.png",
    sameAs: [
      "https://www.facebook.com/nellaikarupatticoffee",
      "https://www.instagram.com/nellaikarupatticoffeeofficial/",
      "https://twitter.com/Karupatticoffee",
      "https://www.linkedin.com/in/nellai-karupatticoffee-0b4516277",
    ],
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Helmet>
          {/* Add your Schema Markup here */}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: ORG_SCHEMA }}
          />
        </Helmet>
        <Helmet>
          {/* Add your Schema Markup here */}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: WEB_SCHEMA }}
          />
        </Helmet>
        <Navbar />
        <Routes>
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/location" element={<Location />} />
          <Route path="/franchisee" element={<Franchisee />} />
          <Route path="/about" element={<More />} />
          <Route path="/terms" element={<TermsCondition />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/refundPolicy" element={<RefundPolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
