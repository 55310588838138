import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const Silde = () => {
  const silde = [
    {
      id: 1,
      img: "../assets/images/home/silde1.png",
    },
    {
      id: 2,
      img: "../assets/images/home/silde2.png",
    },
    {
      id: 3,
      img: "../assets/images/home/silde3.png",
    },
  ];

  const splideOptions = {
    type: "loop", // Infinite loop
    perPage: 1, // Number of visible slides at once
    autoplay: true,
    //  dots: false,
    pagination: false,

    interval: 2000,
    arrows: false,
  };
  return (
    <div className="flex h-[795px] xs:h-[auto]  w-[100%] items-center justify-center bg-[#481212] ">
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "url(../assets/images/home/sildebg.png) no-repeat center",
        }}
      >
        <div className="md:m-auto  md:p-6 flex  h-[100%]  xs:m-4 max-w-[790px] items-center justify-center ">
          <Splide options={splideOptions}>
            {silde?.map((image) => (
              <SplideSlide key={image.id}>
                <img src={image?.img} alt="hut" />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </div>
  );
};

export default Silde;
