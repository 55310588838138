import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css"; // Import the Splide CSS
//import sideImage from '../../../public/assets/images/home/design.png'
const About = () => {
  const images = [
    {
      id: 1,
      img: "../../assets/images/home/tamil.png",
    },
    {
      id: 2,
      img: "../assets/images/home/second.png",
    },
    {
      id: 3,
      img: "../assets/images/home/telugu.png",
    },
    {
      id: 4,
      img: "../assets/images/home/malayalam.png",
    },
  ];

  const splideOptions = {
    type: "loop", // Infinite loop
    perPage: 1, // Number of visible slides at once
    autoplay: true,
    //  dots: false,
    pagination: false,

    interval: 2000,
    arrows: false,
  };

  return (
    <div className="h-auto bg-[#481212] ">
      <div className=" m-auto h-auto  pb-14 sm:px-10  lg:h-[auto] lg:w-[1500px] ">
        <div className="m-auto xs:h-auto lg:h-[600px] ">
          <div className="xs:px-4 xs:py-5 md:py-20 ">
            {/* <div
              className="rounded-bl-0 m-auto items-center justify-center rounded-br-[100px] rounded-tl-[150px] rounded-tr-[100px]  bg-[#FFC20E] text-center  font-avigea
          text-[#702F2F] xs:h-[60px] xs:w-[180px] xs:text-xl sm:w-[280px]
         sm:text-lg md:flex md:h-[100px] md:w-[533px] md:text-4xl "
            >
              <h1>About Nellai Karupatti Coffee</h1>
            </div> */}
            <div
              className="rounded-bl-0 m-auto items-center justify-center rounded-br-[100px] rounded-tl-[150px] rounded-tr-[100px]  bg-[#FFC20E] text-center  font-avigea
          text-[#702F2F] xs:h-[60px] xs:w-[180px] xs:text-xl sm:w-[280px]
         sm:text-lg md:flex md:h-[100px] md:w-[533px] md:text-4xl "
            >
              <h1>About Nellai Karupatti Coffee</h1>
            </div>
            <img
              src="../assets/images/home/homeline.png"
              alt="line"
              class="xs:-mt-1.5 s:-mt-2 md:-mt-6"
            />
          </div>
          <div className=" flex-col text-[#FFC20E] xs:block xs:gap-6 xs:px-5 xs:pb-5 xs:text-xl sm:block md:flex  md:gap-10 md:text-2xl  ">
            <p className="text-justify font-baumans xs:pb-4">
              <span className=" mr-1 font-avigea text-[18px]">
                NELLAI KARUPATTI COFFEE
              </span>
              is an authentic coffee outlet. We are known for our signature
              Karupatti coffee and other Karupatti infused snacks, which are
              both traditional and tasty.
            </p>
            <p className=" text-justify font-avigea text-[#E8A21A] xs:pb-4 xs:text-xl md:text-2xl ">
              To ‘Serve Healthy’ is our priority and ‘Your Satisfaction’ is our
              aim.
            </p>
            <p className=" text-justify font-baumans xs:pb-4 xs:text-xl md:text-2xl">
              Our vision is to serve healthy organic food and reintroduce the
              traditional food system. With the dawn of the modern era, we are
              committed to bring back authentic foods infused with innovation.
            </p>
          </div>
        </div>
        <div className="h-auto items-end xs:w-[70%] md:flex md:w-[100%]">
          <div className="hidden w-[100%] flex-1 md:relative  md:top-2 md:block">
            <img src="../../assets/images/home/design.png" alt="noimg" />
          </div>
          <div className="md:top-38  relative flex-1 pb-20 xs:top-0 xs:ml-20 xs:w-auto xs:pb-6  sm:top-0 md:bottom-0 md:w-[auto] lg:mr-12   lg:pb-0 ">
            <Splide options={splideOptions}>
              {images?.map((image) => (
                <SplideSlide key={image.id}>
                  <img src={image?.img} alt="hut" />
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div
            className="md: top-2 hidden w-[100%]  flex-1 md:relative md:block "
            style={{
              // position: "relative",
              // top: "295px",
              transform: "scaleX(-1)",
            }}
          >
            <img src="../../assets/images/home/design.png" alt="noimg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
