import React from "react";
import Contacts from "./Contacts";
import { Helmet } from "react-helmet";
// import Location from "./Location";
const Contactus = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - Nellai Karupatti Coffee </title>
        <meta
          name="description"
          content="Ready to start your Karupatti Coffee franchise? Reach out to us now. Call +91 63741 14632 (TN & Karnataka) or +91 90802 57459 (Andhra & Telangana). Email: franchise@nkcfoods.com"
        />
        <meta
          name="keywords"
          content="Contact Us, Nellai Karupatti Coffee, Karupatti Coffee Franchise"
        />
        <link rel="canonical" href="https://nellaikarupatticoffee.com/main/" />
        <meta property="og:site_name" content="Nellai Karupatti Coffee" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Contact Us - Nellai Karupatti Coffee"
        />
        <meta
          property="og:description"
          content="Ready to start your Karupatti Coffee franchise? Reach out to us now. Call +91 63741 14632 (TN & Karnataka) or +91 90802 57459 (Andhra & Telangana). Email: franchise@nkcfoods.com"
        />
        <meta
          property="og:url"
          content="https://nellaikarupatticoffee.com/main/"
        />
        <meta
          property="og:image"
          content="https://nellaikarupatticoffee.com/assets/images/Navbar/Nkc%20Logo.png"
        />
      </Helmet>
      <Contacts />
    </>
  );
};

export default Contactus;
