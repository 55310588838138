import React from "react";

const Franchisee = () => {
  return (
     <div className="flex md:h-full max-h-screen items-center justify-center py-[20px]">
    <div className="max-w-[1550px] md:relative">
      <div
        className="m-auto items-center justify-center xs:flex xs:h-[450px] xs:w-[280px]  xs:flex-col md:relative md:flex md:h-[740px] md:w-[480px]"
        style={{
          background:
            "url('../assets/images/contacts/location.png')center/cover",
        }}
      >
        <div className="md:absolute md:right-0 md:top-0 md:p-8  md:py-9 ">
          <p
            className="flex font-avigea text-[#FFC20E] xs:mt-[-50px] xs:w-[100px] xs:pl-28 xs:text-center  xs:text-xs md:h-[147] md:w-[127px]  md:pr-32 md:text-center md:text-xl
            2xl:h-[147px] md:mt-[0px]"
          >
            Our Franchise in all over Asia
          </p>
        </div>
        <div className="font-baumans text-xl text-[#702F2F]">
          <ul className=" pl-2  xs:mt-[40px]">
            <li>1. Tamilnadu</li>
            <li>2. Andhra Pradesh</li>
            <li>3. Telangana</li>
            <li>4. Pondicherry</li>
            <li>5. Maharashtra (opening soon)</li>
            <li>6. Karnataka</li>
            <li>7. Kuwait (opening soon)</li>
            <li>8. Dubai (opening soon)</li>
          </ul>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Franchisee;

