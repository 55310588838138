import React from "react";

import ImageCard from "../../components/ImageCard";

const Hot = () => {
  const image = [
    {
      id: 1,
      img: "../assets/images/product/product1.png",
    },
    {
      id: 2,
      img: "../assets/images/product/product2.png",
    },
    {
      id: 3,
      img: "../assets/images/product/product3.png",
    },
    {
      id: 4,
      img: "../assets/images/product/product4.png",
    },
  ];

  return (
    <div
      className="xs:h-[auto] s:h-[auto] md:h-auto md:w-[100%] md:py-9"
      style={{
        background:
          "url('../assets/images/product/Frame2.png')no-repeat center/cover",
      }}
    >
      <div className="m-auto  md:w-[70%]">
        <div className="flex flex-col items-center justify-center py-20 xs:px-3 xs:py-6 ">
          {/* <div
            className="xs:rounded-bl-0 md:rounded-bl-0 m-auto bg-[#FFC20E] text-center font-avigea xs:flex  xs:h-[55px] xs:w-[250px] xs:items-center xs:justify-center xs:rounded-br-[100px]
          xs:rounded-tl-[150px] xs:rounded-tr-[100px] xs:bg-[#FFC20E] xs:text-center xs:font-avigea xs:text-2xl xs:text-[#481212] s:w-[250px] sm:w-[280px] md:flex  md:h-[98px]  md:w-[480px]   md:items-center
          md:justify-center md:rounded-br-[200px] md:rounded-tl-[400px] md:rounded-tr-[200px]
         md:text-[#481212]  "
          >
            <h1 class="xs:text-3xl md:text-5xl">Hot Beverages</h1>
          </div> */}
          <img src="../assets/images/product/Hot.png" alt="line" />
        </div>
      </div>
      <div className="m-auto w-[90%]">
        <ImageCard data={image} />
      </div>
    </div>
  );
};

export default Hot;
