import React from "react";

const HomePageContent = () => {
  // const width =
  //   window.innerWidth ||
  //   document.documentElement.clientWidth ||
  //   document.body.clientWidth;
  // console.log(width);
  return (
    <>
      <div style={{ width: "100%", height: "auto" }}>
        <video
          src="../assets/images/home/NKCVideo.m4v"
          autoPlay
          loop
          muted
          playsInline
          width="100%"
          height="100%"
        />
      </div>

      <div className="md:max-w-[1500px]">
        <div>
          <div className="flex justify-between gap-10 font-avigea font-normal text-[#702F2F] xs:absolute xs:top-[120px] xs:w-[90%] xs:px-3 xs:text-[10px]  xs:text-xs s:top-[150px]  s:text-2xl s:text-[16px] md:absolute md:left-[200px] md:top-[170px] md:w-[80%] md:p-2 md:text-4xl">
            <div>
              <h5>
                The taste of tradition <br />
              </h5>
              <p className="font-avigea font-normal text-[#481212] xs:text-[12px] s:text-xl md:text-6xl ">
                Nellai Karupatti Coffee
              </p>
            </div>
            <div className="xs:text-[12px] sm:text-[30px] md:-mr-5 md:block ">
              <p className=" s:mb-3  md:mb-7">
                <span className="md:text-5xlmd:p-2 mr-1 rounded-lg  bg-[#702F2F]  text-[#FFC20E] xs:p-1 xs:pt-2  xs:text-xl s:p-2 s:pt-4 s:text-3xl md:pt-4 ">
                  100 +
                </span>
              </p>
              Successful Outlets
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageContent;
