import React from "react";

const RefundPolicy = () => {
  return (
    <div className="w-full bg-[#FFC20E] py-8">
      <div className="mx-auto flex w-[80%] flex-col gap-2">
        <h1 className=" text-center text-[40px] font-semibold text-[#481212] xs:font-roboto  md:font-roboto">
          Refund Policy
        </h1>
        <p className="mt-5 text-sm xs:font-roboto md:font-roboto">
          To be eligible for a return, your item must be in the same condition
          that you received it, unworn or unused, with tags, and in its original
          packaging. You’ll also need the receipt or proof of purchase. Also, an
          unpacking video is a must. Message us with the complaint. If it's a
          valid complaint, the Credit will be done in 5 to 7 working days from
          the date of receiving the shipment. Or it will be added to your
          upcoming invoices. To start a return, you can contact us at
          franchise@nkcfoods.com. If your return is accepted, we’ll send you a
          return shipping label, as well as the instructions on how and where to
          send your package. Items sent back to us without first requesting a
          return will not be accepted. You can always contact us for any return
          questions at franchise@nkcfoods.com.
        </p>
        <strong className="text-base text-[#481212]">Damages and issues</strong>
        <p className="text-sm xs:font-roboto md:font-roboto">
          Please inspect your order upon reception and contact us immediately if
          the item is defective, damaged or if you receive the wrong item, so
          that we can evaluate the issue and make it right.
        </p>
        <strong className="text-base text-[#481212]">Refunds</strong>
        <p className="text-sm xs:font-roboto md:font-roboto">
          We will notify you once we’ve received and inspected your return, and
          let you know if the refund was approved or not. If approved, It will
          be automatically credited on your original payment method, Nor it will
          be added to your upcoming invoices. Please remember it can take some
          time for your bank or credit card company to process and post the
          refund too.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
