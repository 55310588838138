import React from "react";
import ImageCard from "../../components/ImageCard";

const Mennu = () => {
  const menu = [
    {
      id: 1,
      img: "../assets/images/home/menu1.png",
    },
    {
      id: 2,
      img: "../assets/images/home/menu2.png",
    },
    {
      id: 3,
      img: "../assets/images/home/menu3.png",
    },
     {
      id: 3,
      img: "../assets/images/home/menu4.png",
    },
  ];

  return (
    <div
      className="h-[auto] w-[100%]  "
      style={{
        backgroundImage: "url('../assets/images/home/frame.png')",
        marginTop: "-60px",
      }}
    >
      <div className=" m-auto w-[80%]  ">
        <div className="md:py-20 xs:py-4   ">
        <div
            className="rounded-bl-0 m-auto md:flex md:h-[98px] md:w-[533px] items-center justify-center  rounded-br-[100px] rounded-tl-[150px]  rounded-tr-[100px]
          bg-[#481212] text-center font-avigea md:text-[45px] md:pt-3 xs:pt-2
         text-[#FFC20E] xs:h-[40px] xs:w-[180px] xs:text-xl"
          >
            <h1 >Our Diverse menu</h1>
          </div>
          <img
            src="../assets/images/home/linebrown.png"
            alt="line"
            class="xs:-mt-1.5 md:-mt-6 s:-mt-2"
          />
        </div>
        <div
          className=" flex flex-col gap-10  text-[#481212]"
          style={{
            fontSize: "20px",
            fontWeight: "400",
            fontStyle: "normal",
          }}
        >
          <p className=" font-baumans text-justify md:text-2xl xs:text-xl">
            After much research we were successful in bringing tradition and
            modernisation on the same page.This success motivated us to bring
            more out of palm Jaggery which paved the way for sweets made of Palm
            and Sugarcane jaggery. Initially we started with a few sweets and
            savouries. The Sweets were also a hit among the people. And now we
            produce 80+ sweets and savouries made out of only Palm and Sugarcane
            Jaggery.
          </p>
        </div>
      </div>
      <div className="] m-auto w-[90%]">
        <ImageCard data={menu} />
      </div>
    </div>
  );
};

export default Mennu;
