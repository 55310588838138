import Form from "../Franchise/Form";

const Franchise = () => {
  return (
    <div className="2xl:w-[100%] bg-[#FFC20E] xs:h-[auto] ">
      <div className="m-auto 2xl:h-auto  xs:h-[auto] xs:w-[auto] max-w-[1500px] items-center xs:flex xs:flex-col-reverse   xs:px-4 lg:flex lg:flex-col xl:flex xl:flex-row lg:px-24 ">
        <div className="flex-1 pt-6 font-avigea text-[#702F2F] text-4xl md:text-6xl">
          <h1 className="flex">Successfully running</h1>
          <div className="lg:flex">
            <span className="mr-2 flex items-center justify-center xs:pt-4 rounded-lg bg-[#702F2F] text-7xl text-[#FFC20E] xs:h-auto xs:w-[200px] md:h-[99px] md:w-[240px]">
              100 +
            </span>
            <h1 className="md:pt-8 xs:pb-4 xs:pt-3">outlets</h1>
          </div>
          <div className="h-auto md:py-12 font-baumans text-lg text-[#702F2F] lg:w-[645px] text-justify">
            <p>
              We opened our very first store in 2018 with the motive of providing Karupatti coffee. Today we have expanded 80+ branches across 4 states over the last 6 years. We have achieved steady and sustainable growth in the industry. Our evolving menu and various additions of branches support this statement. Our success can be attributed to our well-established operation system, comprehensive training program, strong reputation, and most importantly, the invaluable partnerships we have formed with our franchisees. Our goal is to provide ideas for greater profit with less investment and to achieve stable progress. Consumer satisfaction and progressive growth is our main focus.
            </p>
          </div>

          <div className="flex-col gap-7 md:flex py-3">
            <h3 className="font-avigea text-[#702F2F] md:text-4xl xs:text-[22px]">
              NELLAI KARUPATTI COFFEE <br />
              THE FRANCHISE THAT WILL GROW
            </h3>
            <p className="font-baumans text-lg text-[#702F2F] xs:w-[100%] md:w-[100%] text-justify">
              Join us as a karupati coffee franchise partner and become part of our thriving business in this rapidly expanding industry.
            </p>
          </div>
        </div>
          <div>
            <Form />
          </div>
      </div>
    </div>
  );
};

export default Franchise;
