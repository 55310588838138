import React from "react";
// import Franchisee from "./Franchisee";

const Location = () => {
  return (
    <div className="max-h-[1100px] md:h-full w-[100%] bg-[#300808] md:py-16 xs:h-[auto]">
      <div className="m-auto max-w-[1600px] xs:flex xs:flex-col md:flex md:flex-row ">
        <div className=" m-auto mt-24 xs:h-[304px] xs:w-[300px] md:h-[856px] md:w-[960px]">
          <img src="../assets/images/contacts/Map.png" alt="line" />
        </div>
        {/* <Franchisee /> */}
      </div>
    </div>
  );
};

export default Location;
