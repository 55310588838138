import React from "react";

const ImageCard = ({ data }) => {
  return (
    <div className="flex-wrap justify-center xs:flex xs:flex-wrap xs:justify-center md:flex">
      {data?.map((data) => (
        <img
          src={data?.img}
          alt="hut"
          className="xs:flex xs:w-[350px]  xs:flex-col xs:px-9 xs:py-5 sm:w-1/2 md:w-1/4 "
        />
      ))}
    </div>
  );
};

export default ImageCard;
