import React from "react";
import Hot from "../product/Hot";
import Sweet from "../product/Sweet";
import Milet from "../product/Milet";
import Savories from "../product/Savories";
import { Helmet } from "react-helmet";
const Products = () => {
  return (
    <div>
      <Helmet>
        <title>Karupatti Coffee Menu- Nellai Karupatti Coffee </title>
        <meta
          name="description"
          content="Explore our famous Karupatti Coffee, Tea, and traditional treats. Savor sweet, savory, millet delights & ice cream at our shop. Visit our shop to taste it!"
        />
        <meta
          name="keywords"
          content="Karupatti Coffee, Thati Bellam Coffee, Nellai Karupatti Coffee, Karupatti Coffee Menu, Nellai Karupatti Coffee Products"
        />
        <link
          rel="canonical"
          href="https://nellaikarupatticoffee.com/products/"
        />
        <meta property="og:site_name" content="Nellai Karupatti Coffee" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Karupatti Coffee Menu- Nellai Karupatti Coffee"
        />
        <meta
          property="og:description"
          content="Explore our famous Karupatti Coffee, Tea, and traditional treats. Savor sweet, savory, millet delights & ice cream at our shop. Visit our shop to taste it!"
        />
        <meta
          property="og:url"
          content="https://nellaikarupatticoffee.com/products/"
        />
        <meta
          property="og:image"
          content="https://nellaikarupatticoffee.com/assets/images/Navbar/Nkc%20Logo.png"
        />
      </Helmet>
      <Hot />
      <Sweet />
      <Milet />
      <Savories />
    </div>
  );
};

export default Products;
