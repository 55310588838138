import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full bg-[#FFC20E] py-8">
      <div className="mx-auto flex w-[80%] flex-col gap-2">
        <h1 className="text-center text-[40px] font-semibold text-[#481212] xs:font-roboto  md:font-roboto">
          Privacy Policy
        </h1>
        <p className="mt-5 text-sm xs:font-roboto  md:font-roboto">
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from our
          site.
        </p>
        <strong className="text-base text-[#481212]">
          Personal information we collect
        </strong>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this automatically
          collected information as “Device Information”.
        </p>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          We collect Device Information using the following technologies: -
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org. - “Log files” track actions occurring
          on the Site, and collect data including your IP address, browser type,
          Internet service provider, referring/exit pages, and date/time stamps.
          - “Web beacons”, “tags”, and “pixels” are electronic files used to
          record information about how you browse the Site.
        </p>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          Additionally, when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit card/debit card numbers, etc.) email address, and
          phone number. We refer to this information as “Order Information”.
        </p>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking both about Device Information and Order Information.
        </p>
        <strong className="text-base text-[#481212]">
          How do we use your personal information?
        </strong>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information to: - Communicate with you; - Screen our orders for
          potential risk or fraud; and - When in line with the preferences you
          have shared with us, provide you with information or advertising
          relating to our products or services.
        </p>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </p>
        <strong className="text-base text-[#481212]">
          Sharing your personal Information
        </strong>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. We also use Google
          Analytics to help us understand how our customers use the Site -- you
          can read more about how Google uses your Personal Information here:
          https://www.google.com/intl/en/policies/privacy/. You can also opt out
          of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant, or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>
        <strong className="text-base text-[#481212]">
          Behavioural advertising
        </strong>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          As described above, we use your Personal Information to provide you
          with targeted advertisements or marketing communications we believe
          may be of interest to you. For more information about how targeted
          advertising works, you can visit the Network Advertising Initiative’s
          (“NAI”) educational page at
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
        </p>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          You can opt out of targeted advertising by using the links below:
          <br className="pt-2" />
          -Facebook: https://www.facebook.com/settings/?tab=ads <br />- Google:
          https://www.google.com/settings/ads/anonymous <br />- Bing:
          https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
        </p>
        <strong className="text-base text-[#481212]">Do not track</strong>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </p>
        <strong className="text-base text-[#481212]">Data retention</strong>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>
        <strong className="text-base text-[#481212]">Changes</strong>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons.
        </p>
        <strong className="text-base text-[#481212]">Contact us</strong>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e‑mail at franchise@nkcfoods.com{" "}
        </p>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          Nellai Karupatti Coffee (Head Office)
        </p>
        <p className="text-sm xs:font-roboto  md:font-roboto">
          Old No 69, New No 61, Nolambur Service Road, <br /> Mogappair West,
          Chennai, TamilNadu 600037, India.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
