import React from "react";
const Cofe = (props) => {
  return (
    <div >
      {props?.data?.map((info) => {
        return (
          <div
            className="md:flex md:w-[100%] md:gap-14 md:py-5 xs:flex xs:w-[100%]  xs:py-4 xs:gap-4  "
            style={{ color: props?.color }}
          >
            <div className="h-[30px] w-[5%] xs:w-[40px] xs:pt-2">
              <img src={info?.img} alt="no img" width="100%" height="100%" />
            </div>
            <div className="w-[90%]">
              <h5>{info?.title}</h5>
              <h5>{info?.items}</h5>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Cofe;
