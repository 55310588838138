import React from "react";
import Cofe from "../../components/Cofe";
const Steps = (props) => {
  return (
    <div
      className="m-auto w-[100%]  items-center  align-middle"
      style={{ backgroundImage: "url('../assets/images/Franchise/Rec.png')" }}
    >
      <div className="m-auto max-w-[1400px] items-center ">
        <img
          src="../assets/images/Franchise/main.png"
          alt="line"
          width="100%"
        />
        <div
          className="rounded-bl-0 s:w[300px] m-auto flex items-center justify-center rounded-br-[150px] rounded-tl-[250px] rounded-tr-[150px] bg-[#FFC20E] text-center font-avigea text-[#481212] xs:-mt-12  xs:mb-5 xs:h-[50px] xs:w-[200px] xs:text-xs  sm:w-[430px] 
          md:-mt-24  md:h-[98px] md:w-[800px] lg:h-[94px] md:text-4xl 
         "
        >
          {props?.text}
        </div>
        
        <div
          className="m-auto w-[90%] gap-28 md:flex md:px-7 md:py-20  "
          style={{ flexDirection: "row-reverse" }}
        >
          <div className="">
            <img
              src="../assets/images/Franchise/second.png"
              alt="line"
             
              style={{ display: "flex", margin: "auto" }}
            />
          </div>
          <div className="font-avigea text-[#FFC20E] xs:text-lg s:py-6 md:w-[50%] md:text-3xl">
            <Cofe data={props?.content} flexDirection={props?.flexDirection} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
