import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const Contacts = () => {
  return (
    <div
      className="min-h-full  xs:min-h-full xs:py-[20px]  sm:py-4 md:h-[auto] md:w-[100%] "
      style={{
        background:
          "url('../assets/images/contacts/background.png') no-repeat center/cover",
      }}
    >
      <div className="xs:flex xs:flex-col xs:gap-8 md:m-auto md:flex md:min-h-[100vh]  md:max-w-[1400px]  md:flex-col md:gap-5 lg:flex  lg:flex-row   ">
        {contactDetails?.map((data) => {
          return (
            <div
              key={data.id}
              className="xs:m-auto xs:h-[349px] xs:w-[300px] sm:w-[330px] md:m-auto md:h-[449px] md:w-[364px]  "
              style={{
                background:
                  "url('../assets/images/contacts/main4.png') no-repeat center/contain",
              }}
            >
              <div className="W-[100%] h-[100%]">
                <p className="h-[40%] pt-10 text-center font-avigea text-xl text-[#481212] xs:w-[100%] md:w-[100%]">
                  {data?.title}
                </p>
                <div
                  className={`flex h-[${
                    data?.title === "Email" ? "40%" : "40%"
                  }] flex-col px-4`}
                >
                  {data?.address1 && (
                    <div className="m-auto flex">
                      {data.icon === "phone" && (
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="mx-3 h-[14px] w-[14px] rounded-full border border-[#FFC20E] p-2  text-[#FFC20E] md:px-2"
                        />
                      )}
                      {data.icon === "envelope" && (
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="mx-3 h-[14px] w-[14px] rounded-full border border-[#FFC20E] p-2 text-[#FFC20E]"
                        />
                      )}
                      {data.icon === "map" && data?.address1 && (
                        <img
                          src=" ../assets/images/contacts/icon.png"
                          alt="line"
                          className="mx-3 h-[28px] w-[28px]"
                        />
                      )}
                      <div className=" flex w-[100%] font-avigea text-[#FFC20E]   sm:text-xl ">
                        <p>{data?.address1}</p>
                      </div>
                    </div>
                  )}
                  {data?.address2 && (
                    <div className="flex border-t-2 border-[#FFC20E] pt-2">
                      {data.icon === "phone" && data?.logo}
                      {data.icon === "envelope" && data?.logo}
                      <div className="font-avigea text-[#FFC20E]  xs:px-5 md:text-2xl ">
                        <p>{data?.address2}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const contactDetails = [
  {
    id: 1,
    title: "Contact",
    address1: "Tamil Nadu / Karnataka 63741 14632",
    address2: "Andhra /Telangan 90802 57459",
    icon: "phone",
    logo: (
      <FontAwesomeIcon
        icon={faPhone}
        className="mx-3 h-[14px] w-[14px] rounded-full border border-[#FFC20E] p-2 text-[#FFC20E] md:text-2xl"
      />
    ),
  },
  {
    id: 2,
    title: "Address",
    address1: "Nellai Karupatti Coffee (Head Office)",
    address2:
      "Old No 69, New No 61, Nolambur Service Road, Mogappair West, Chennai, Tamilnadu 600037, India.",

    icon: "map",
  },

  {
    id: 3,
    title: "Email",
    address1: "franchise@nkcfoods.com",
    icon: "envelope",
    logo: (
      <FontAwesomeIcon
        icon={faEnvelope}
        className=" h-[14px] w-[14px] rounded-full border border-[#FFC20E] p-2  text-[#FFC20E]"
      />
    ),
  },
];

export default Contacts;
