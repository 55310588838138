import React from "react";
import ImageCard from "../../components/ImageCard";

const Savories = () => {
  const data = [
    {
      id: 1,
      img: "../assets/images/product/sweet8.png",
    },
    {
      id: 2,
      img: "../assets/images/product/sweet9.png",
    },
    {
      id: 3,
      img: "../assets/images/product/sweet10.png",
    },
  ];
  return (
    <div
      className=" m-auto xs:h-[auto] md:h-auto md:py-10 md:w-[100%] "
      style={{
        backgroundImage: "url('../assets/images/product/Frame4.png')",
      }}
    >
      <div className=" m-auto md:w-[70%]">
        <div className=" py-20 xs:py-6 xs:px-3">
          
          <img
            src="../assets/images/product/jack.png"
            alt="line"
         
          />
        </div>
      </div>
      <div className="m-auto w-[100%]">
        <ImageCard data={data} />
      </div>
    </div>
  );
};

export default Savories;
