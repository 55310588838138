import React from 'react'
import ImageCard from "../../components/ImageCard";

const Sweet = () => {
    const sweet = [
        {
            id: 1,
            img: "../assets/images/product/sweet1.png",
        },
        {
            id: 2,
            img: "../assets/images/product/sweet2.png",
        },{
            id: 3,
            img: "../assets/images/product/sweet3.png",
        },{
            id: 4,
            img: "../assets/images/product/sweet4.png",
        },{
            id: 5,
            img: "../assets/images/product/sweet5.png",
        },{
            id: 6,
            img: "../assets/images/product/sweet6.png",
        },{
            id: 7,
            img: "../assets/images/product/sweet7.png",
        },
    ]
  return (
    <div
      className=" xs:h-[auto] s:h-[auto]  md:h-auto md:w-full md:py-10  "
      style={{
        backgroundImage: "url('../assets/images/product/Frame3.png')",
      }}
    >
      <div className="m-auto md:w-[70%]">
        <div className="py-20  xs:px-3 xs:py-6 ">
          {/* <div
            className="xs:rounded-bl-0  md:rounded-bl-0 m-auto bg-[#481212] text-center  font-avigea xs:flex  xs:h-[70px] xs:w-[230px]
          xs:items-center xs:justify-center xs:rounded-br-[100px] xs:rounded-tl-[150px] xs:rounded-tr-[100px] xs:bg-[#481212] xs:text-center xs:font-avigea xs:text-3xl xs:text-[#E8A21A] s:w-[250px]  sm:w-[280px] md:flex  md:h-[98px]
          md:w-[513px] md:items-center md:justify-center md:rounded-br-[200px] md:rounded-tl-[400px]
         md:rounded-tr-[200px] md:text-[#E8A21A] "
          >
            <h1 class="xs:text-3xl md:text-5xl">Sweet & Savories</h1>
          </div> */}
          <img
            src="../assets/images/product/sweet.png"
            alt="line"
            class="xs:-mt-1.5 s:-mt-2 sm:-mt-3 md:-mt-5 lg:-mt-4"
          />
        </div>
      </div>
      <div className="m-auto w-[90%]">
        <ImageCard data={sweet} />
      </div>
    </div>
  );
}

export default Sweet