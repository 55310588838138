import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
const Footer = () => {
  const currentyear = new Date().getFullYear();

  return (
    <footer className=" bg-[#FFC20E] font-avigea text-4xl font-normal text-[#481212]  md:h-auto">
      <div class="m-auto max-w-[90%] grid-flow-col py-10  md:grid lg:grid-rows-2   2xl:grid-rows-1  ">
        <div className="m-auto  w-[100%] md:flex-col md:gap-16 lg:flex">
          <div className=" m-auto w-[100%] xs:flex xs:gap-2 xs:pb-14 md:flex md:pb-0">
            <img
              src="../assets/images/footer/Nkc Logo.png"
              alt="logo"
              className="  w-[170px h-[100px]"
            ></img>
            <p className=" flex text-[25rem] xs:pt-12  xs:text-xl md:h-[57px] md:w-[280px] md:pt-12">
              Nellai
              <br /> Karupatti Coffee
            </p>
          </div>

          <div className="ml-0.5 items-baseline gap-5  xs:hidden md:flex  ">
            <Link
              to="https://www.facebook.com/nellaikarupatticoffee?mibextid=ZbWKwL"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                className="h-[18px] w-[18px] cursor-pointer rounded-full  border border-[#481212]  p-2  px-2 text-[#481212]"
              />
            </Link>
            <Link
              to="https://www.instagram.com/nellaikarupatticoffeeofficial/?next=%2F"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className=" h-[18px] w-[18px]  cursor-pointer rounded-full border border-[#481212]  p-2 text-[#481212]"
              />
            </Link>
            <Link to="https://twitter.com/nkcofficialpage" target="_blank">
              <FontAwesomeIcon
                icon={faTwitter}
                className="h-[18px] w-[18px] cursor-pointer rounded-full border border-[#481212] p-2 text-[#481212]"
              />
            </Link>
            <Link
              to="https://www.linkedin.com/in/nellai-karupatticoffee-0b4516277/"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faLinkedinIn}
                className="h-[18px] w-[18px]  cursor-pointer rounded-full border border-[#481212] p-2 text-[#481212]"
              />
            </Link>
          </div>
        </div>

        <div className=" xs:bg-#481212 border-[#E8A21A] md:mt-10 md:border-l-2 md:border-r-2 md:px-20">
          <ul className="flex flex-col gap-8 text-xl font-semibold">
            <li className=" cursor-pointer rounded-md border-2 border-solid border-[#481212] py-1 pl-7 font-roboto text-[#481212] hover:bg-[#481212]  hover:text-[#E8A21A] xs:pr-10 md:h-[40px] md:w-40">
              <Link to="/" target="_parent">
                Home
              </Link>
            </li>
            <li className="h-[40px] cursor-pointer rounded-md border-2 border-solid border-[#481212] py-1 pl-7 font-roboto text-[#481212] hover:bg-[#481212] hover:text-[#E8A21A] md:w-40">
              <Link to="/franchise" target="_parent">
                Franchise
              </Link>
            </li>
            <li className="cursor-pointer rounded-md border-2 border-solid border-[#481212] py-1 pl-7 font-roboto text-[#481212] hover:bg-[#481212] hover:text-[#E8A21A] md:w-40">
              <Link to="/products" target="_parent">
                Products
              </Link>
            </li>

            <li className="cursor-pointer rounded-md border-2 border-solid border-[#481212] py-1  pl-7 font-roboto hover:bg-[#481212] hover:text-[#E8A21A] md:w-40">
              <Link to="/about" target="_parent">
                About us
              </Link>
            </li>
            <li className="cursor-pointer rounded-md border-2 border-solid border-[#481212] py-1  pl-7 font-roboto hover:bg-[#481212] hover:text-[#E8A21A] md:w-40">
              <Link to="/contactus" target="_parent">
                Contact US
              </Link>
            </li>
          </ul>
        </div>

        <div className="  mt-10 flex flex-col border-[#E8A21A]  text-2xl xs:gap-4 md:gap-9  md:border-r-2 md:px-10">
          <h1>To Know More About us</h1>
          <div className="flex gap-5 text-xl font-normal ">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="rounded-full border border-[#481212]   p-1 text-[#481212]"
            />
            <p
              className="xs:font-roboto  xs:text-lg md:font-roboto md:text-xl"
              style={{
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              franchise@nkcfoods.com
            </p>
          </div>
          <div className="flex gap-5 text-xl font-normal  ">
            <FontAwesomeIcon
              icon={faGlobe}
              className="rounded-full border border-[#481212]  p-1 text-[#481212]"
            />
            <p className="text-xl  xs:font-roboto xs:text-lg md:font-roboto">
              nellaikarupatticoffee.com
            </p>
          </div>
          <p className="cursor-pointer font-roboto  text-xl font-semibold">
            <Link to="/terms" target="_blank">
              Terms of service
            </Link>
          </p>
          <p className="cursor-pointer font-roboto  text-xl font-semibold">
            <Link to="/privacyPolicy" target="_blank">
              Privacy Policy
            </Link>
          </p>
          <p className=" cursor-pointer font-roboto  text-xl font-semibold">
            <Link to="/refundPolicy" target="_blank">
              Refund Policy
            </Link>
          </p>
        </div>

        <div className=" flex-col  xs:flex xs:gap-4 md:flex md:px-8">
          <div className="mt-6 flex  xs:flex xs:gap-4">
            <FontAwesomeIcon
              icon={faPhone}
              className="h-[14px] w-[14px] rounded-full border border-[#481212] p-2 text-2xl  text-[#481212] md:mx-2"
            />
            <div className=" xs:border-b-2 xs:border-[#E8A21A]  xs:text-xl  md:mb-6 md:border-b-2 md:border-[#E8A21A] md:pb-8 md:text-3xl">
              <p>
                TamilNadu/Karnataka <br />
                Pondicherry/Kerala <br />
                6374114632
              </p>
            </div>
          </div>
          <div className="flex xs:flex xs:gap-4 xs:pb-6">
            <FontAwesomeIcon
              icon={faPhone}
              className="h-[14px] w-[14px] rounded-full  border border-[#481212] p-2 text-2xl  text-[#481212] md:mx-2"
            />
            <div className="top-4 xs:text-xl md:text-3xl ">
              <p>
                Andhra / Telangana <br />
                9080257459
              </p>
            </div>
          </div>
        </div>

        <div className="ml-0.5 flex  items-baseline gap-5 md:hidden ">
          <Link
            to="https://www.facebook.com/nellaikarupatticoffee?mibextid=ZbWKwL"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              className="h-[18px] w-[18px] cursor-pointer rounded-full  border border-[#481212]  p-2  px-2 text-[#481212]"
            />
          </Link>
          <Link
            to="https://www.instagram.com/nellaikarupatticoffeeofficial/?next=%2F"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className=" h-[18px] w-[18px]  cursor-pointer rounded-full border border-[#481212]  p-2 text-[#481212]"
            />
          </Link>
          <Link to="https://twitter.com/nkcofficialpage" target="_blank">
            <FontAwesomeIcon
              icon={faTwitter}
              className="h-[18px] w-[18px] cursor-pointer rounded-full border border-[#481212] p-2 text-[#481212]"
            />
          </Link>
          <Link
            to="https://www.linkedin.com/in/nellai-karupatticoffee-0b4516277/"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faLinkedinIn}
              className="h-[18px] w-[18px]  cursor-pointer rounded-full border border-[#481212] p-2 text-[#481212]"
            />
          </Link>
        </div>
      </div>
      <div className="flex h-[109px] items-center justify-center bg-[#481212] font-inter text-[#702F2F] xs:items-center xs:text-[14px]  md:text-xl">
        <div className="bg-[#481212]">
          <p
            className="  "
            style={{
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            © Copyright {currentyear} . All rights reserved By &nbsp;
            <span className="text-[#E8A21A82]">AptonWorks</span>
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
